import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import React from "react";
import { DeviceValidationItem, DeviceValidationItemKeys } from "./types";
import { currencyFormatter } from "./utils";

interface Props {
  data: DeviceValidationItem[];
  isLoading?: boolean;
}

type SortDirection = "asc" | "desc";
type SortKey = keyof DeviceValidationItem;

const AccountChargesTable: React.FC<Props> = ({ data, isLoading = false }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [sortDirection, setSortDirection] =
    React.useState<SortDirection>("asc");
  const [sortBy, setSortBy] = React.useState<SortKey>(
    DeviceValidationItemKeys.CarrierAccountNumber
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key: SortKey) => {
    const isAsc = sortBy === key && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortBy(key);
  };

  const filteredData = React.useMemo(() => {
    // First filter out non-account level items
    const accountLevelItems = data.filter(
      (item) => item[DeviceValidationItemKeys.IsAccountLevelSummary] === true
    );

    // Then apply search filtering
    const searchValue = searchTerm.toLowerCase().replace(/[$,]/g, "");
    return accountLevelItems.filter((item) => {
      // Check text fields
      if (
        item[DeviceValidationItemKeys.CarrierName]
          ?.toString()
          .toLowerCase()
          .includes(searchValue) ||
        item[DeviceValidationItemKeys.CarrierAccountNumber]
          ?.toString()
          .toLowerCase()
          .includes(searchValue)
      ) {
        return true;
      }

      // Check numeric fields
      const numericFields = [
        DeviceValidationItemKeys.CalculatedEquipmentCharges,
        DeviceValidationItemKeys.CalculatedMrc,
        DeviceValidationItemKeys.CalculatedUsageCharges,
        DeviceValidationItemKeys.CalculatedTaxes,
        DeviceValidationItemKeys.CalculatedFees,
        DeviceValidationItemKeys.CalculatedOtherCharges,
        DeviceValidationItemKeys.TotalCharges,
      ];

      return numericFields.some((field) => {
        const value = item[field];
        if (value === null || value === undefined) return false;
        // Remove $ and , from the value string for comparison
        return value.toString().replace(/[$,]/g, "").includes(searchValue);
      });
    });
  }, [data, searchTerm]);

  const sortedData = React.useMemo(() => {
    return [...filteredData].sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];

      if (aValue === bValue) return 0;
      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
      }

      const comparison = String(aValue).localeCompare(String(bValue));
      return sortDirection === "asc" ? comparison : -comparison;
    });
  }, [filteredData, sortBy, sortDirection]);

  const paginatedData = React.useMemo(() => {
    return sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [sortedData, page, rowsPerPage]);

  const formatCurrency = (value?: number) =>
    value ? currencyFormatter.format(value) : "$0.00";

  const handleExport = () => {
    // Implement CSV export similar to the existing codebase
    const headers = [
      "CARRIER",
      "ACCOUNT",
      "EQP$",
      "MRC$",
      "USAGE$",
      "TAX$",
      "FEES$",
      "OCC$",
      "TOTAL$",
    ];
    const csvData = data.map((row) => [
      row[DeviceValidationItemKeys.CarrierName],
      row[DeviceValidationItemKeys.CarrierAccountNumber],
      row[DeviceValidationItemKeys.CalculatedEquipmentCharges],
      row[DeviceValidationItemKeys.CalculatedMrc],
      row[DeviceValidationItemKeys.CalculatedUsageCharges],
      row[DeviceValidationItemKeys.CalculatedTaxes],
      row[DeviceValidationItemKeys.CalculatedFees],
      row[DeviceValidationItemKeys.CalculatedOtherCharges],
      row[DeviceValidationItemKeys.TotalCharges],
    ]);

    const csvContent = [
      headers.join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "account_charges.csv";
    link.click();
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold mb-4">
          {isLoading ? (
            <Skeleton width={200} role="skeleton" />
          ) : (
            "Account Level Charges"
          )}
        </h2>

        <div className="flex justify-end mb-4">
          {isLoading ? (
            <Skeleton width={120} height={36} role="skeleton" />
          ) : (
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={handleExport}
            >
              Export Data
            </Button>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <TextField
          label="Search by any value"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={isLoading}
          sx={{
            minWidth: 300,
          }}
        />
      </div>

      <div className="overflow-x-auto">
        <Table size="small">
          <TableHead>
            <TableRow className="bg-primary-800">
              <TableCell className="!text-white">
                <TableSortLabel
                  active={sortBy === DeviceValidationItemKeys.CarrierName}
                  direction={sortDirection}
                  onClick={() =>
                    handleSort(DeviceValidationItemKeys.CarrierName)
                  }
                  className="!text-white"
                  sx={{
                    "& .MuiTableSortLabel-icon": { color: "white !important" },
                  }}
                >
                  CARRIER
                </TableSortLabel>
              </TableCell>
              <TableCell className="!text-white">
                <TableSortLabel
                  active={
                    sortBy === DeviceValidationItemKeys.CarrierAccountNumber
                  }
                  direction={sortDirection}
                  onClick={() =>
                    handleSort(DeviceValidationItemKeys.CarrierAccountNumber)
                  }
                  className="!text-white"
                  sx={{
                    "& .MuiTableSortLabel-icon": { color: "white !important" },
                  }}
                >
                  ACCOUNT
                </TableSortLabel>
              </TableCell>
              <TableCell className="!text-white" align="right">
                <TableSortLabel
                  active={
                    sortBy ===
                    DeviceValidationItemKeys.CalculatedEquipmentCharges
                  }
                  direction={sortDirection}
                  onClick={() =>
                    handleSort(
                      DeviceValidationItemKeys.CalculatedEquipmentCharges
                    )
                  }
                  className="!text-white"
                  sx={{
                    "& .MuiTableSortLabel-icon": { color: "white !important" },
                  }}
                >
                  EQP$
                </TableSortLabel>
              </TableCell>
              <TableCell className="!text-white" align="right">
                <TableSortLabel
                  active={sortBy === DeviceValidationItemKeys.CalculatedMrc}
                  direction={sortDirection}
                  onClick={() =>
                    handleSort(DeviceValidationItemKeys.CalculatedMrc)
                  }
                  className="!text-white"
                  sx={{
                    "& .MuiTableSortLabel-icon": { color: "white !important" },
                  }}
                >
                  MRC$
                </TableSortLabel>
              </TableCell>
              <TableCell className="!text-white" align="right">
                <TableSortLabel
                  active={
                    sortBy === DeviceValidationItemKeys.CalculatedUsageCharges
                  }
                  direction={sortDirection}
                  onClick={() =>
                    handleSort(DeviceValidationItemKeys.CalculatedUsageCharges)
                  }
                  className="!text-white"
                  sx={{
                    "& .MuiTableSortLabel-icon": { color: "white !important" },
                  }}
                >
                  USAGE$
                </TableSortLabel>
              </TableCell>
              <TableCell className="!text-white" align="right">
                <TableSortLabel
                  active={sortBy === DeviceValidationItemKeys.CalculatedTaxes}
                  direction={sortDirection}
                  onClick={() =>
                    handleSort(DeviceValidationItemKeys.CalculatedTaxes)
                  }
                  className="!text-white"
                  sx={{
                    "& .MuiTableSortLabel-icon": { color: "white !important" },
                  }}
                >
                  TAX$
                </TableSortLabel>
              </TableCell>
              <TableCell className="!text-white" align="right">
                <TableSortLabel
                  active={sortBy === DeviceValidationItemKeys.CalculatedFees}
                  direction={sortDirection}
                  onClick={() =>
                    handleSort(DeviceValidationItemKeys.CalculatedFees)
                  }
                  className="!text-white"
                  sx={{
                    "& .MuiTableSortLabel-icon": { color: "white !important" },
                  }}
                >
                  FEES$
                </TableSortLabel>
              </TableCell>
              <TableCell className="!text-white" align="right">
                <TableSortLabel
                  active={
                    sortBy === DeviceValidationItemKeys.CalculatedOtherCharges
                  }
                  direction={sortDirection}
                  onClick={() =>
                    handleSort(DeviceValidationItemKeys.CalculatedOtherCharges)
                  }
                  className="!text-white"
                  sx={{
                    "& .MuiTableSortLabel-icon": { color: "white !important" },
                  }}
                >
                  OCC$
                </TableSortLabel>
              </TableCell>
              <TableCell className="!text-white" align="right">
                <TableSortLabel
                  active={sortBy === DeviceValidationItemKeys.TotalCharges}
                  direction={sortDirection}
                  onClick={() =>
                    handleSort(DeviceValidationItemKeys.TotalCharges)
                  }
                  className="!text-white"
                  sx={{
                    "& .MuiTableSortLabel-icon": { color: "white !important" },
                  }}
                >
                  TOTAL$
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? // Show 3 skeleton rows while loading
                Array.from({ length: 3 }).map((_, index) => (
                  <TableRow key={`skeleton-${index}`}>
                    <TableCell>
                      <Skeleton role="skeleton" />
                    </TableCell>
                    <TableCell>
                      <Skeleton role="skeleton" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton role="skeleton" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton role="skeleton" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton role="skeleton" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton role="skeleton" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton role="skeleton" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton role="skeleton" />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton role="skeleton" />
                    </TableCell>
                  </TableRow>
                ))
              : paginatedData.map((item) => (
                  <TableRow key={item._id} className="even:bg-slate-200">
                    <TableCell>
                      {item[DeviceValidationItemKeys.CarrierName]}
                    </TableCell>
                    <TableCell>
                      {item[DeviceValidationItemKeys.CarrierAccountNumber]}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(
                        item[
                          DeviceValidationItemKeys.CalculatedEquipmentCharges
                        ]
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(
                        item[DeviceValidationItemKeys.CalculatedMrc]
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(
                        item[DeviceValidationItemKeys.CalculatedUsageCharges]
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(
                        item[DeviceValidationItemKeys.CalculatedTaxes]
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(
                        item[DeviceValidationItemKeys.CalculatedFees]
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(
                        item[DeviceValidationItemKeys.CalculatedOtherCharges]
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(
                        item[DeviceValidationItemKeys.TotalCharges]
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {!isLoading && (
          <TablePagination
            component="div"
            count={filteredData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        )}
      </div>
    </div>
  );
};

export default AccountChargesTable;
