import { Skeleton, Tab, Tabs } from "@mui/material";
import React from "react";

interface Props {
  availableCarriers: string[];
  selectedCarrier: string;
  onCarrierChange: (carrier: string) => void;
  isLoading?: boolean;
}

const CarrierTabs: React.FC<Props> = ({
  availableCarriers,
  selectedCarrier,
  onCarrierChange,
  isLoading = false,
}) => {
  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        height={48}
        sx={{ borderRadius: 1 }}
        role="skeleton"
      />
    );
  }

  return (
    <Tabs
      value={availableCarriers.indexOf(selectedCarrier)}
      onChange={(_, index) => onCarrierChange(availableCarriers[index])}
      indicatorColor="primary"
      textColor="primary"
    >
      {availableCarriers.map((carrier) => (
        <Tab key={carrier} label={carrier} />
      ))}
    </Tabs>
  );
};

export default CarrierTabs;
