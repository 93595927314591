import { Card } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { uniqueId } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AccountChargesTable from "./AccountChargesTable";
import { fetchDeviceValidations, fetchStatementMonths } from "./api";
import BillValidationHeader from "./BillValidationHeader";
import CarrierTabs from "./CarrierTabs";
import SpendingChart from "./SpendingChart";
import SpendingTotalsTable from "./SpendingTotalsTable";
import { AvailableCarriers, StatementMonthsResponse } from "./types";

const BillValidation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [loadingMonths, setLoadingMonths] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    queryParams.get("date") || ""
  );
  const [selectedCarrier, setSelectedCarrier] = useState(
    queryParams.get("carrier") || "All"
  );
  const [statementMonths, setStatementMonths] =
    useState<StatementMonthsResponse>({
      all_months: [],
    });

  const { data: deviceValidations = [], isLoading: loadingChartData } =
    useQuery({
      queryKey: ["deviceValidation", selectedDate],
      queryFn: async () => {
        if (!selectedDate) return [];
        try {
          const data = await fetchDeviceValidations(selectedDate);
          return data.map((item) => ({
            ...item,
            _id: uniqueId(),
          }));
        } catch (error) {
          Sentry.captureException(error);
          toast.error("There was an error fetching the report data");
          throw error;
        }
      },
      enabled: !!selectedDate,
    });

  const updateQueryParams = useCallback(
    (date?: string, carrier?: string) => {
      const newParams = new URLSearchParams(location.search);

      if (date !== undefined) {
        newParams.set("date", date);
      }

      if (carrier !== undefined) {
        newParams.set("carrier", carrier);
      }

      navigate({ search: newParams.toString() }, { replace: true });
    },
    [location.search, navigate]
  );

  const handleDateChange = (newDate: string) => {
    setSelectedDate(newDate);
    updateQueryParams(newDate, undefined);
  };

  const handleCarrierChange = (newCarrier: string) => {
    setSelectedCarrier(newCarrier);
    updateQueryParams(undefined, newCarrier);
  };

  const filteredData = useMemo(() => {
    if (selectedCarrier === "All") return deviceValidations;
    return deviceValidations.filter(
      (item) => item.carrier_name === selectedCarrier
    );
  }, [selectedCarrier, deviceValidations]);

  useEffect(() => {
    const fetchAvailableMonths = async () => {
      setLoadingMonths(true);
      try {
        const data = await fetchStatementMonths();
        setStatementMonths(data);

        if (!queryParams.get("date") && data.all_months?.length > 0) {
          const sortedMonths = data.all_months
            .map((item) => item.statement_month)
            .sort((a, b) => b.localeCompare(a));

          setSelectedDate(sortedMonths[0]);
          updateQueryParams(sortedMonths[0], undefined);
        }
      } catch (error) {
        Sentry.captureException(error);
        toast.error("There was an error fetching the statement months");
      } finally {
        setLoadingMonths(false);
      }
    };

    fetchAvailableMonths();
  }, [queryParams, updateQueryParams]);

  const availableMonths = statementMonths.all_months
    ?.map((item) => item.statement_month)
    .sort((a, b) => b.localeCompare(a));

  const availableCarriers: AvailableCarriers[] = useMemo(() => {
    return [
      "All",
      ...new Set(
        deviceValidations.map((item) => item.carrier_name).filter(Boolean)
      ),
    ];
  }, [deviceValidations]);

  return (
    <div className="p-4">
      <BillValidationHeader
        selectedDate={selectedDate}
        availableMonths={availableMonths}
        onMonthChange={handleDateChange}
        loadingMonths={loadingMonths}
      />

      <CarrierTabs
        selectedCarrier={selectedCarrier}
        onCarrierChange={handleCarrierChange}
        availableCarriers={availableCarriers}
        isLoading={loadingChartData}
      />

      <Card className="p-4">
        <h2 className="text-xl font-semibold mb-4">
          Monthly Invoice Spending Totals
        </h2>
        <div className="flex flex-row gap-8">
          <div className="w-1/2">
            <SpendingChart
              data={deviceValidations}
              selectedCarrier={selectedCarrier}
              isLoading={loadingChartData}
            />
          </div>
          <div className="w-1/2">
            <SpendingTotalsTable
              data={filteredData}
              isLoading={loadingChartData}
            />
          </div>
        </div>
      </Card>

      <Card className="mt-4 p-4">
        <AccountChargesTable data={filteredData} isLoading={loadingChartData} />
      </Card>
    </div>
  );
};

export default BillValidation;
