import { buildApiUrl } from "@react-ms-apps/common";
import axios from "axios";
import moment from "moment";
import { DeviceValidationItem, StatementMonthsResponse } from "./types";

export const fetchDeviceValidations = async (selectedDate: string) => {
  const response = await axios.get<DeviceValidationItem[]>(
    buildApiUrl(
      `/api/report/DashBillValidationCharts?statement_month=${moment(
        selectedDate,
        "YYYY-MM"
      ).format("YYYY-MM-DD")}`
    )
  );
  return response.data;
};

export const fetchStatementMonths = async () => {
  const response = await axios.get<StatementMonthsResponse>(
    buildApiUrl("/api/statement_months_list")
  );
  return response.data;
};

interface UniqueDevicesCountResponse {
  unique_device_counts: number;
}

export const fetchUniqueDevicesCount = async (
  selectedDate: string
): Promise<number> => {
  const response = await axios.get<UniqueDevicesCountResponse>(
    buildApiUrl(
      `/api/aggregated_data?datasets=unique_device_counts&statement_month=${selectedDate}`
    )
  );

  return response.data.unique_device_counts;
};
