import { Box, Card, Skeleton } from "@mui/material";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchUniqueDevicesCount } from "./api";

export default function BillValidationHeader({
  selectedDate,
  availableMonths,
  onMonthChange,
  loadingMonths = false,
}: {
  selectedDate: string;
  availableMonths: string[];
  onMonthChange: (month: string) => void;
  loadingMonths: boolean;
}) {
  const [loadingUniqueDevices, setLoadingUniqueDevices] = useState(false);
  const [totalUniqueDevices, setTotalUniqueDevices] = useState<number>(0);

  useEffect(() => {
    const fetchDeviceCount = async () => {
      if (!selectedDate) {
        setTotalUniqueDevices(0);
        return;
      }

      try {
        setLoadingUniqueDevices(true);
        const uniqueDevicesCount = await fetchUniqueDevicesCount(selectedDate);
        setTotalUniqueDevices(uniqueDevicesCount);
      } catch (error) {
        Sentry.captureException(error);
        toast.error("There was an error fetching the device count");
      } finally {
        setLoadingUniqueDevices(false);
      }
    };

    fetchDeviceCount();
  }, [selectedDate]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value;

    // format to "YYYY-MM-DD"
    const formattedDate = moment(newDate).format("YYYY-MM-DD");
    onMonthChange(formattedDate);
  };

  // Calculate min and max dates from availableMonths
  const minDate =
    availableMonths.length > 0
      ? availableMonths.reduce((min, date) => (date < min ? date : min))
      : "";

  const maxDate =
    availableMonths.length > 0
      ? availableMonths.reduce((max, date) => (date > max ? date : max))
      : "";

  const minDateFormatted = moment(minDate).format("YYYY-MM");
  const maxDateFormatted = moment(maxDate).format("YYYY-MM");
  const selectedDateFormatted = moment(selectedDate).format("YYYY-MM");

  const renderDeviceCount = () => {
    if (loadingUniqueDevices) {
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Skeleton
            variant="text"
            width={28}
            height={28}
            sx={{ bgcolor: "rgba(255,255,255,0.3)" }}
            role="skeleton"
          />
        </Box>
      );
    }
    return totalUniqueDevices;
  };

  return (
    <Card className="mb-4 py-0 pl-4 pr-2">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-8">
          <h1 className="text-2xl font-bold">Bill Validation</h1>
          <div className="flex items-center gap-2">
            <label className="mr-2">Date:</label>
            {loadingMonths ? (
              <Skeleton
                variant="rectangular"
                width={160}
                height={38}
                className="inline-block"
              />
            ) : (
              <input
                type="month"
                value={selectedDateFormatted}
                onChange={handleDateChange}
                min={minDateFormatted}
                max={maxDateFormatted}
                className="p-2 border rounded"
                role="listbox"
              />
            )}
          </div>
        </div>

        <div className="flex items-center gap-2 bg-blue-400 rounded-md p-2 px-4 text-white">
          <span className="font-semibold">Total Unique Devices:</span>{" "}
          <span>{renderDeviceCount()}</span>
        </div>
      </div>
    </Card>
  );
}
