import { Skeleton } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { useMemo } from "react";
import { chartColors } from "../../Constants/chart-colors";
import { DeviceValidationCostKeys, DeviceValidationItem } from "./types";

interface Props {
  data: DeviceValidationItem[];
  selectedCarrier: string;
  isLoading?: boolean;
}

const SpendingChart: React.FC<Props> = ({
  data,
  selectedCarrier,
  isLoading = false,
}) => {
  // Transform data into Nivo's expected format
  const formattedData = useMemo(() => {
    // Define all possible device types
    const deviceTypes = {
      Smart: 0,
      Phone: 0,
      Data: 0,
      Tablet: 0,
      Wearable: 0,
    };

    // Group devices by carrier
    const groupedByCarrier = data.reduce((acc, item) => {
      if (!acc[item.carrier_name]) {
        acc[item.carrier_name] = [];
      }
      acc[item.carrier_name].push(item);
      return acc;
    }, {} as Record<string, DeviceValidationItem[]>);

    // Create the formatted data structure
    return Object.entries(groupedByCarrier).map(([carrier, devices]) => {
      // Start with a copy of the default device types
      const deviceCosts = { ...deviceTypes };

      // Sum up the costs for devices that exist
      devices.forEach((device) => {
        DeviceValidationCostKeys.forEach((key) => {
          deviceCosts[
            device.formatted_device_type as keyof typeof deviceCosts
          ] += device[key] as number;
        });
      });

      return {
        carrier,
        ...deviceCosts,
      };
    });
  }, [data]);

  // Get all unique device keys for the chart
  const deviceKeys = useMemo(() => {
    if (!formattedData || formattedData.length === 0) return [];

    return Object.keys(formattedData[0]).filter((key) => key !== "carrier");
  }, [formattedData]);

  if (isLoading) {
    return (
      <div style={{ height: 200 }}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          role="skeleton"
        />
      </div>
    );
  }

  return (
    <div style={{ height: formattedData.length * 25 + 100 }}>
      <ResponsiveBar
        data={formattedData}
        keys={deviceKeys}
        indexBy="carrier"
        layout="horizontal"
        valueFormat={(value) => `$${value.toFixed(2)}`}
        margin={{ top: 20, right: 130, bottom: 20, left: 100 }}
        padding={0.5}
        innerPadding={2}
        colors={chartColors}
        borderRadius={1}
        enableLabel={false}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
        }}
        axisBottom={{
          format: (value) => `$${value}`,
          tickSize: 5,
          tickPadding: 5,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        tooltip={({ id, value, color, indexValue }) => (
          <div className="bg-white border border-solid border-gray-300 rounded-md p-2 flex flex-col gap-2 justify-center items-center">
            <div className="text-sm text-gray-500 font-bold">{indexValue}</div>
            <div className="text-sm text-gray-500">
              {id}:{" "}
              <strong>
                $
                {value.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </strong>
            </div>
          </div>
        )}
        legends={[
          {
            dataFrom: "keys",
            anchor: "right",
            direction: "column",
            translateX: 120,
            itemWidth: 100,
            itemHeight: 20,
          },
        ]}
        minValue="auto"
        enableGridX={true}
      />
    </div>
  );
};

export default SpendingChart;
