import ROUTES from "@react-ms-apps/common/constants/routes";
import { Route, Routes } from "react-router-dom";
import BillValidation from "../Components/BillValidation";
import CumulativeSavings from "../Components/CumulativeSavings";

const Dashboards = () => {
  return (
    <Routes>
      <Route
        path={`${ROUTES.DASHBOARDS.CUMULATIVE_SAVINGS}/*`}
        element={<CumulativeSavings />}
      />

      <Route
        path={`${ROUTES.DASHBOARDS.BILL_VALIDATION}/*`}
        element={<BillValidation />}
      />
    </Routes>
  );
};

export default Dashboards;
