import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useMemo } from "react";
import {
  CarrierTotals,
  DeviceValidationItem,
  DeviceValidationItemKeys,
} from "./types";
import { currencyFormatter } from "./utils";

interface Props {
  data: DeviceValidationItem[];
  isLoading?: boolean;
}

const SpendingTotalsTable: React.FC<Props> = ({ data, isLoading = false }) => {
  const { carrierTotals, grandTotals } = useMemo(() => {
    // Group by carrier
    const groupedByCarrier = data.reduce((acc, curr) => {
      const carrierName = curr[DeviceValidationItemKeys.CarrierName];
      if (!acc[carrierName]) {
        acc[carrierName] = {
          cnt: 0,
          eqp: 0,
          mrc: 0,
          usage: 0,
          tax: 0,
          fees: 0,
          occ: 0,
          total: 0,
        };
      }

      acc[carrierName].cnt += curr[DeviceValidationItemKeys.DeviceCount];
      acc[carrierName].eqp +=
        curr[DeviceValidationItemKeys.CalculatedEquipmentCharges];
      acc[carrierName].mrc += curr[DeviceValidationItemKeys.CalculatedMrc];
      acc[carrierName].usage +=
        curr[DeviceValidationItemKeys.CalculatedUsageCharges];
      acc[carrierName].tax += curr[DeviceValidationItemKeys.CalculatedTaxes];
      acc[carrierName].fees += curr[DeviceValidationItemKeys.CalculatedFees];
      acc[carrierName].occ +=
        curr[DeviceValidationItemKeys.CalculatedOtherCharges];
      acc[carrierName].total += curr[DeviceValidationItemKeys.TotalCharges];

      return acc;
    }, {} as Record<string, CarrierTotals>);

    // Calculate grand totals
    const grandTotals = Object.values(groupedByCarrier).reduce(
      (acc, curr) => ({
        cnt: acc.cnt + curr.cnt,
        eqp: acc.eqp + curr.eqp,
        mrc: acc.mrc + curr.mrc,
        usage: acc.usage + curr.usage,
        tax: acc.tax + curr.tax,
        fees: acc.fees + curr.fees,
        occ: acc.occ + curr.occ,
        total: acc.total + curr.total,
      }),
      {
        cnt: 0,
        eqp: 0,
        mrc: 0,
        usage: 0,
        tax: 0,
        fees: 0,
        occ: 0,
        total: 0,
      }
    );

    return {
      carrierTotals: groupedByCarrier,
      grandTotals,
    };
  }, [data]);

  const formatCurrency = (value?: number) =>
    value ? currencyFormatter.format(value) : "$0.00";

  if (isLoading) {
    return (
      <div className="overflow-x-auto">
        <Table size="small">
          <TableHead>
            <TableRow className="bg-primary-800">
              <TableCell className="!text-white">CARRIER</TableCell>
              <TableCell className="!text-white" align="right">
                CNT
              </TableCell>
              <TableCell className="!text-white" align="right">
                EQP$
              </TableCell>
              <TableCell className="!text-white" align="right">
                MRC$
              </TableCell>
              <TableCell className="!text-white" align="right">
                USAGE$
              </TableCell>
              <TableCell className="!text-white" align="right">
                TAX$
              </TableCell>
              <TableCell className="!text-white" align="right">
                FEES$
              </TableCell>
              <TableCell className="!text-white" align="right">
                OCC$
              </TableCell>
              <TableCell className="!text-white" align="right">
                TOTAL$
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(3)].map((_, index) => (
              <TableRow key={index} className="even:bg-slate-200">
                <TableCell>
                  <Skeleton animation="wave" role="skeleton" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" role="skeleton" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" role="skeleton" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" role="skeleton" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" role="skeleton" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" role="skeleton" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" role="skeleton" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" role="skeleton" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" role="skeleton" />
                </TableCell>
              </TableRow>
            ))}
            <TableRow className="bg-white">
              <TableCell>
                <Skeleton animation="wave" role="skeleton" />
              </TableCell>
              <TableCell align="right">
                <Skeleton animation="wave" role="skeleton" />
              </TableCell>
              <TableCell align="right">
                <Skeleton animation="wave" role="skeleton" />
              </TableCell>
              <TableCell align="right">
                <Skeleton animation="wave" role="skeleton" />
              </TableCell>
              <TableCell align="right">
                <Skeleton animation="wave" role="skeleton" />
              </TableCell>
              <TableCell align="right">
                <Skeleton animation="wave" role="skeleton" />
              </TableCell>
              <TableCell align="right">
                <Skeleton animation="wave" role="skeleton" />
              </TableCell>
              <TableCell align="right">
                <Skeleton animation="wave" role="skeleton" />
              </TableCell>
              <TableCell align="right">
                <Skeleton animation="wave" role="skeleton" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <Table size="small">
        <TableHead>
          <TableRow className="bg-primary-800">
            <TableCell className="!text-white">CARRIER</TableCell>
            <TableCell className="!text-white" align="right">
              CNT
            </TableCell>
            <TableCell className="!text-white" align="right">
              EQP$
            </TableCell>
            <TableCell className="!text-white" align="right">
              MRC$
            </TableCell>
            <TableCell className="!text-white" align="right">
              USAGE$
            </TableCell>
            <TableCell className="!text-white" align="right">
              TAX$
            </TableCell>
            <TableCell className="!text-white" align="right">
              FEES$
            </TableCell>
            <TableCell className="!text-white" align="right">
              OCC$
            </TableCell>
            <TableCell className="!text-white" align="right">
              TOTAL$
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(carrierTotals).map(([carrier, totals]) => (
            <TableRow key={carrier} className="even:bg-slate-200">
              <TableCell>{carrier}</TableCell>
              <TableCell align="right">{totals.cnt}</TableCell>
              <TableCell align="right">{formatCurrency(totals.eqp)}</TableCell>
              <TableCell align="right">{formatCurrency(totals.mrc)}</TableCell>
              <TableCell align="right">
                {formatCurrency(totals.usage)}
              </TableCell>
              <TableCell align="right">{formatCurrency(totals.tax)}</TableCell>
              <TableCell align="right">{formatCurrency(totals.fees)}</TableCell>
              <TableCell align="right">{formatCurrency(totals.occ)}</TableCell>
              <TableCell align="right">
                {formatCurrency(totals.total)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow className="bg-white">
            <TableCell>
              <strong>TOTAL INVOICE</strong>
            </TableCell>
            <TableCell align="right">
              <strong>{grandTotals.cnt}</strong>
            </TableCell>
            <TableCell align="right">
              <strong>{formatCurrency(grandTotals.eqp)}</strong>
            </TableCell>
            <TableCell align="right">
              <strong>{formatCurrency(grandTotals.mrc)}</strong>
            </TableCell>
            <TableCell align="right">
              <strong>{formatCurrency(grandTotals.usage)}</strong>
            </TableCell>
            <TableCell align="right">
              <strong>{formatCurrency(grandTotals.tax)}</strong>
            </TableCell>
            <TableCell align="right">
              <strong>{formatCurrency(grandTotals.fees)}</strong>
            </TableCell>
            <TableCell align="right">
              <strong>{formatCurrency(grandTotals.occ)}</strong>
            </TableCell>
            <TableCell align="right">
              <strong>{formatCurrency(grandTotals.total)}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default SpendingTotalsTable;
