import { Skeleton } from "@mui/material";
import { NavbarOuterWrapper, NavbarWrapper } from "./styled";

export const NavBarSkeleton = () => {
  return (
    <NavbarOuterWrapper>
      <NavbarWrapper>
        {[...Array(5)].map((_, index) => (
          <Skeleton
            key={index}
            variant="text"
            width={100}
            height={35}
            sx={{
              borderRadius: 0.5,
              mr: 1,
            }}
          />
        ))}
      </NavbarWrapper>

      <div className="flex flex-row items-center justify-end">
        <Skeleton
          variant="rounded"
          width={35}
          height={35}
          sx={{
            borderRadius: 0.5,
            mr: 1,
          }}
        />
        <Skeleton
          variant="rounded"
          width={35}
          height={35}
          sx={{ borderRadius: 0.5 }}
        />
      </div>
    </NavbarOuterWrapper>
  );
};
