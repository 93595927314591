import { CopyAll } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Portal,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  CarrierList,
  CatalogDevice,
  Device,
  fetchCarriers,
} from "@react-ms-apps/common/api/catalog-manager";
import { classNames } from "@react-ms-apps/common/utils/styles";
import * as Sentry from "@sentry/react";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useCatalogManager } from "../CatalogManagerProvider";
import TabPanel from "../CustomTabPanel";
import NewCatalogEntryDialog from "../Manager/NewCatalogEntryDialog";
import { EditDeviceTabEnum } from "./constants";

interface CatalogDevicesTabProps {
  activeTab: EditDeviceTabEnum;
  updatedDevice: Device;
  onUpdateDevice: (device: Device) => void;
}

export default function CatalogDevicesTab({
  activeTab,
  updatedDevice,
  onUpdateDevice,
}: CatalogDevicesTabProps) {
  const { deleteCatalogDevice } = useCatalogManager();
  const { catalog_devices: initialCatalogDevices } = updatedDevice;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramsCatalogDeviceId = queryParams.get("catalogDeviceId");

  const initialCatalogDeviceId = useMemo(() => {
    if (paramsCatalogDeviceId) {
      return Number(paramsCatalogDeviceId);
    }

    // return the first catalog device id
    return initialCatalogDevices?.[0]?.catalog_device_id || null;
  }, [initialCatalogDevices, paramsCatalogDeviceId]);

  const [selectedCatalogDeviceId, setSelectedCatalogDeviceId] = useState<
    number | null
  >(initialCatalogDeviceId);

  const catalogDevices = useMemo(() => {
    return updatedDevice.catalog_devices || [];
  }, [updatedDevice]);

  const [
    selectedCatalogDeviceForDeletion,
    setSelectedCatalogDeviceForDeletion,
  ] = useState<number | null>(null);
  const [deletingCatalogDevice, setDeletingCatalogDevice] =
    useState<boolean>(false);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [loadingCarriers, setLoadingCarriers] = useState<boolean>(true);
  const [carriers, setCarriers] = useState<CarrierList>([]);

  const selectedCatalogDevice = useMemo(() => {
    if (!catalogDevices || !selectedCatalogDeviceId) {
      return null;
    }

    return catalogDevices.find(
      (cd) => cd.catalog_device_id === selectedCatalogDeviceId
    );
  }, [catalogDevices, selectedCatalogDeviceId]);

  const getCarriers = useCallback(async () => {
    setLoadingCarriers(true);

    try {
      const carriers = (await fetchCarriers())
        // sort alphabetically
        .sort((a, b) => a.name.localeCompare(b.name));

      setCarriers(carriers);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingCarriers(false);
    }
  }, []);

  const getCarrierName = useCallback(
    (carrier_id: number) => {
      const carrier = carriers.find((c) => c.carrier_id === carrier_id);
      return carrier?.name ?? "";
    },
    [carriers]
  );

  const handleFieldChange = useCallback(
    (field: keyof CatalogDevice, value: string | number | boolean | null) => {
      if (!selectedCatalogDevice) {
        return;
      }

      const updatedCatalogDevice: CatalogDevice = {
        ...selectedCatalogDevice,
        [field]: value,
      };

      const updatedCatalogDevices = catalogDevices.map((cd) =>
        cd.catalog_device_id === selectedCatalogDeviceId
          ? updatedCatalogDevice
          : cd
      );

      onUpdateDevice({
        ...updatedDevice,
        catalog_devices: updatedCatalogDevices,
      });
    },
    [
      catalogDevices,
      onUpdateDevice,
      selectedCatalogDevice,
      selectedCatalogDeviceId,
      updatedDevice,
    ]
  );

  const handleDeleteCatalogDevice = useCallback(async () => {
    if (!selectedCatalogDeviceForDeletion) {
      return;
    }

    setDeletingCatalogDevice(true);

    try {
      await deleteCatalogDevice(selectedCatalogDeviceForDeletion);
      // remove the deleted catalog device from the list
      const updatedCatalogDevices = catalogDevices.filter(
        (cd) => cd.catalog_device_id !== selectedCatalogDeviceForDeletion
      );

      setSelectedCatalogDeviceForDeletion(null);
      onUpdateDevice({
        ...updatedDevice,
        catalog_devices: updatedCatalogDevices,
      });

      // select the first catalog device
      if (updatedCatalogDevices.length > 0) {
        setSelectedCatalogDeviceId(updatedCatalogDevices[0].catalog_device_id);
      }

      toast.success("Catalog Device deleted");
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Error deleting catalog device");
    } finally {
      setDeletingCatalogDevice(false);
    }
  }, [
    catalogDevices,
    deleteCatalogDevice,
    onUpdateDevice,
    selectedCatalogDeviceForDeletion,
    updatedDevice,
  ]);

  const handleNewCatalogDevice = useCallback(
    (updatedCatalogDevice: CatalogDevice) => {
      // add the new catalog device to the list
      onUpdateDevice({
        ...updatedDevice,
        catalog_devices: [...catalogDevices, updatedCatalogDevice],
      });

      // select the new catalog device if it's the only one
      if (catalogDevices.length === 0) {
        setSelectedCatalogDeviceId(updatedCatalogDevice.catalog_device_id);
      }
    },
    [catalogDevices, onUpdateDevice, updatedDevice]
  );

  const handlePriceChange =
    (fieldName: keyof CatalogDevice) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value === "") {
        handleFieldChange(fieldName, null);
      } else {
        const parsedValue = parseFloat(value);
        const numValue = Math.max(0, Number(parsedValue.toFixed(2)));
        handleFieldChange(fieldName, numValue);
      }
    };

  const handlePriceBlur =
    (fieldName: keyof CatalogDevice) =>
    (e: React.FocusEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value === "") {
        handleFieldChange(fieldName, null);
      } else {
        const parsedValue = parseFloat(value);
        const numValue = Math.max(0, Number(parsedValue.toFixed(2)));
        handleFieldChange(fieldName, numValue);
      }
    };

  // load carrier names
  useEffect(() => {
    getCarriers();
  }, [getCarriers]);

  if (!updatedDevice?.device_id) {
    return (
      <TabPanel
        value={activeTab}
        index={EditDeviceTabEnum.CATALOG_DEVICES}
        className="flex flex-1 flex-col gap-4"
      >
        <Alert severity="warning">Device must be saved first.</Alert>
      </TabPanel>
    );
  }

  return (
    <>
      <TabPanel
        value={activeTab}
        index={EditDeviceTabEnum.CATALOG_DEVICES}
        className="flex flex-1 flex-col gap-4"
      >
        {loadingCarriers && (
          <div className="flex flex-1 items-center justify-center mt-10">
            <CircularProgress />
          </div>
        )}

        {!loadingCarriers && (
          <div className="flex flex-row gap-2">
            {catalogDevices &&
              !isEmpty(catalogDevices) &&
              catalogDevices.map((cd) => (
                <Paper
                  color="primary"
                  key={cd.catalog_device_id}
                  component={Button}
                  onClick={() =>
                    setSelectedCatalogDeviceId(cd.catalog_device_id)
                  }
                  className={classNames(
                    "cursor-pointer flex items-center justify-center !border !border-solid !min-w-[150px]",
                    selectedCatalogDevice?.catalog_device_id !==
                      cd.catalog_device_id && "!border-transparent",
                    selectedCatalogDevice?.catalog_device_id ===
                      cd.catalog_device_id &&
                      "!border-primary-500 !text-primary-700"
                  )}
                >
                  {getCarrierName(cd.carrier_id)}
                </Paper>
              ))}

            <Paper
              component={Button}
              className="cursor-pointer flex items-center justify-center"
              onClick={() => setShowAddDialog(true)}
            >
              Add Catalog Device
            </Paper>
          </div>
        )}

        {selectedCatalogDevice && (
          <Card square={false} className="!rounded-md">
            <CardActions className="flex flex-row justify-between">
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={selectedCatalogDevice.active} />}
                  label="Active"
                  onChange={(e, checked) =>
                    handleFieldChange("active", checked)
                  }
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={selectedCatalogDevice.out_of_stock} />
                  }
                  label="Out of Stock"
                  onChange={(e, checked) =>
                    handleFieldChange("out_of_stock", checked)
                  }
                />
              </FormGroup>

              <Button
                variant="outlined"
                color="error"
                onClick={() =>
                  setSelectedCatalogDeviceForDeletion(selectedCatalogDeviceId)
                }
              >
                Remove Carrier
              </Button>
            </CardActions>

            <CardContent className="flex flex-col gap-4">
              {selectedCatalogDevice.catalog_device_id && (
                <TextField
                  fullWidth
                  label="Catalog Device ID"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={selectedCatalogDevice.catalog_device_id || ""}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(
                              selectedCatalogDevice?.catalog_device_id?.toString() ||
                                ""
                            );
                            toast.success(
                              "Catalog Device ID copied to clipboard"
                            );
                          }}
                        >
                          <CopyAll />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              <FormControl
                fullWidth
                sx={{
                  mb: 1,
                }}
              >
                <InputLabel id="new-catalog-entry-carrier-label">
                  Carrier
                </InputLabel>
                <Select
                  labelId="new-catalog-entry-carrier-label"
                  label="Carrier"
                  value={selectedCatalogDevice.carrier_id || ""}
                  onChange={(e) =>
                    onUpdateDevice({
                      ...updatedDevice,
                      catalog_devices: updatedDevice.catalog_devices.map(
                        (cd) => {
                          if (
                            cd.catalog_device_id === selectedCatalogDeviceId
                          ) {
                            return {
                              ...cd,
                              carrier_id: Number(e.target.value),
                            };
                          }
                          return cd;
                        }
                      ),
                    })
                  }
                >
                  {carriers.map((carrier) => (
                    <MenuItem value={carrier.carrier_id}>
                      {carrier.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="Guidelines"
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedCatalogDevice.guidelines || ""}
                onChange={(e) =>
                  handleFieldChange("guidelines", e.target.value)
                }
              />

              <TextField
                fullWidth
                id="pro-price"
                label="Pro Price"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 0.01,
                }}
                value={selectedCatalogDevice.pro_price ?? ""}
                onChange={handlePriceChange("pro_price")}
                onBlur={handlePriceBlur("pro_price")}
              />

              <TextField
                fullWidth
                id="purchase-price"
                label="Purchase Price"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 0.01,
                }}
                value={selectedCatalogDevice.purchase_price ?? ""}
                onChange={handlePriceChange("purchase_price")}
                onBlur={handlePriceBlur("purchase_price")}
              />

              <TextField
                fullWidth
                id="retail-price"
                label="Retail Price"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 0.01,
                }}
                value={selectedCatalogDevice.retail_price ?? ""}
                onChange={handlePriceChange("retail_price")}
                onBlur={handlePriceBlur("retail_price")}
              />

              <TextField
                fullWidth
                type="number"
                label="Relative Rank"
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedCatalogDevice.relative_ranking || ""}
                onChange={(e) =>
                  handleFieldChange("relative_ranking", Number(e.target.value))
                }
              />
            </CardContent>
          </Card>
        )}

        {!!selectedCatalogDeviceForDeletion && (
          <Portal>
            <Dialog open>
              <DialogTitle>Remove Carrier</DialogTitle>

              <DialogContent>
                <Typography>
                  Are you sure you want to remove this Carrier entry from{" "}
                  {updatedDevice.manufacturer} {updatedDevice.model}?
                </Typography>
              </DialogContent>

              <DialogActions>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setSelectedCatalogDeviceForDeletion(null)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={deletingCatalogDevice}
                  disabled={deletingCatalogDevice}
                  variant="contained"
                  color="error"
                  onClick={handleDeleteCatalogDevice}
                >
                  Delete
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </Portal>
        )}
      </TabPanel>

      {showAddDialog && (
        <NewCatalogEntryDialog
          onClose={() => setShowAddDialog(false)}
          selectedDevice={updatedDevice.device_id}
          onSaveSuccess={handleNewCatalogDevice}
        />
      )}
    </>
  );
}
